.multicolor-gradient {
    background: rgb(21,21,21);
    background: linear-gradient(45deg, rgba(21,21,21,1) 21%, #2d3a7b 50%, #ff22d6 86%);
    position: relative
}

.blue-pink-gradient {
  background: rgb(21,21,21);
  background: linear-gradient(45deg, #066f55 21%, #5e2d7b 50%, #03566d 86%);
  position: relative
}

.blue-lightblue-gradient {
  background: rgb(21,21,21);
  background: linear-gradient(45deg, #3300A5 21%, #1B77C2 50%, #00FFE2 86%);
  position: relative
}

.gray-gradient {
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgb(224, 224, 224) 100%);
}

.dark-gray-gradient {
    background: rgb(255,255,255);
    background: linear-gradient(90deg, #484851 0%, #1e1c2e 100%);
}

.blue-gray-gradient {
  background: rgb(255,255,255);
  background: linear-gradient(90deg, #484851 0%, #23B1F7 100%);
}

.vertical-gray-gradient {
    background: rgb(224,224,231);
    background: linear-gradient(180deg, rgba(224,224,231,1) 0%, rgba(255,255,255,1) 100%);
}

.card-light-30 {
  background: rgb(255, 255, 255, 0.3);
}

.card-height-primary {
 min-height: 900px;
 position: relative
}

.rounded-35px {
    border-radius: 35px;
}

.rounded-20px {
    border-radius: 20px;
}

.rounded-25px {
    border-radius: 25px;
}

/* AppButton.css */
.ihover i {
  transition: transform 0.3s ease; /* Add a transition for the 'transform' property */
}

.ihover:hover i {
  transform: translateX(4px); /* Move the icon 4 pixels to the right on hover */
}

/* Base styles for the title */
.large-title {
    font-size: 7.1rem;
    letter-spacing: -0.17rem;
    line-height: 9rem;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .card-height-primary {
        min-height: 550px;
        position: relative
       }
       
    .large-title {
      font-size: 3rem; 
      letter-spacing: -0.1rem;
    line-height: 3.5rem;
    }

    .rounded-35px {
        border-radius: 20px;
    }

    .rounded-25px {
        border-radius: 15px;
    }
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .blinking {
    animation: blink 0.5s infinite;
  }

  .overflow-hidden-show {
    overflow: auto;
  }
  .overflow-hidden-show::-webkit-scrollbar-thumb {
        background-color: transparent !important; 
  }

  /* src/App.css */
    .App {
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
    }
  
  .item-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .item {
    width: 200px;
    height: 100px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  

  .btn.btn-black {
    color: var(--kt-dark-inverse);
    background-color: #00000095;
}

.bg-gray {
    background-color: #5A5B6A;
}

.hover-scale {
    transition: transform 0.3s ease; /* Add a smooth transition for the scaling effect */
  }
  
  .hover-scale:hover {
    transform: scale(1.03); /* Scale the element by 10% on hover */
    cursor: pointer;
  }

  .top-right {
    top:20px;
    right:20px
  }

  .rotate-45 {
    rotate: -45deg;
  }

  .rotate-90 {
    rotate: 90deg !important;
  }
  
  .rotate-90-n {
    rotate: -90deg !important;
  }
  
  .rotate-180 {
    rotate: 180deg !important;
  }
  
  .rotate-180-animate {
    transition: transform 0.5s ease; /* Add a smooth transition effect */
    transform: rotate(180deg);
  }
  
  .rotate-0-animate {
    transition: transform 0.5s ease; /* Add a smooth transition effect */
    transform: rotate(0);
  }
  
  .max-width-1550 {
    max-width: 1550px;
  }

  .card-2 {
    background-color: #fefefe;
    border: 0.5px solid #d5d5d5;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0);
    transition: box-shadow 0.2s ease-in-out;
  }

  .card-2:hover {
    background-color: #fff;
    border: 0.5px solid transparent;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.16);
  }

  ul.no-bullets {
    list-style: none;
  }

  .width-fit-content {
    width: fit-content;
  }

  .accordion-button {
    background-color: transparent !important;
  }

  .accordion-button:not(.collapsed) {
    box-shadow: none !important;
  }

  tr {
    vertical-align: middle;
  }

  .screen-reader-text {
    clip: rect(1px,1px,1px,1px);
    word-wrap: normal!important;
    border: 0;
    clip-path: inset(50%);
    height: 28px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute!important;
    width: 21px;
}

[data-kt-sticky-landing-header=on] .landing-header {
  z-index: 110 !important;
}

.swap-coin-size {
  width: 350px;
}

.swap-coin-container {
  height: 350px; 
}

@media (max-width: 768px) {
  .swap-coin-size {
    width: 150px;
  }
  .swap-coin-container {
    height: 150px;
    width: 100%;
    max-width: 400px;
  } 
}

.swap-coin-size-widget {
width: 250px;
}

.swap-coin-container-widget {
  height: 250px; 
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .swap-coin-size-widget {
    width: 150px;
  }
  .swap-coin-container-widget {
    height: 150px;
    width: 100%;
    max-width: 400px;
  } 
}






  