@font-face {
    font-family: JetBrain;
    src: url(../fonts/jetbrain/JetBrainsMono-Regular.ttf);
}

.font-ios {
    font-family: 'SF Pro Display', sans-serif !important;
}

.fm-jetbrain {
    font-family: JetBrain;
}

.bg-ios-light {
    background-color: var(--bg-ios-light) !important;
}

.p-ios-card {
    padding: 16px !important;
}

.pl-ios-16 {
    padding-left: 16px !important;
}

.mt-ios-9 {
    margin-top: 9px !important;
}

.ml-ios-17 {
    margin-left: 17px !important;
}

.ml-ios-42 {
    margin-left: 42px !important;
}

.mr-ios-12 {
    margin-right: 12px !important;
}

.mr-ios-15 {
    margin-right: 15px !important;
}

.my-ios-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.py-ios-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.fs-ios-34 {
    font-size: 34px !important;
}

.fs-ios-13 {
    font-size: 13px !important;
}

.fs-ios-22 {
    font-size: 22px !important;
}

.fs-ios-17 {
    font-size: 17px !important;
}

.w-ios-50px {
    width: 50px !important;
}

.w-ios-80px {
    width: 80px !important;
}

.me-ios-15px {
    margin-right: 15px !important;
}

.fw-ios-700 {
    font-weight: 700 !important;
}

.mb-ios-12 {
    margin-bottom: 12px;
}

.mb-ios-22 {
    margin-bottom: 22px;
}

.mb-ios-46 {
    margin-bottom: 46px !important;
}

.text-ios-light {
    color: var(--text-ios-light) !important;
}

.rounded-ios {
    border-radius: 8px;
}

.address-style {
    letter-spacing: 3px;
    line-height: 20px;
}

.powered-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 8px 0 10px;
    width: 100%;
    background-color: var(--op-white);
    color: white;
    text-align: center;
  }

  .bg-custom-white {
    background-color: var(--op-white);
  }

.separator-ios {
    border: .1px solid #7b7b7b30 !important;
}

.tel-alert {
    position: fixed !important;
    width: -webkit-fill-available;
    bottom: 0;
    z-index: 4;
    backdrop-filter: blur(4px);
    background-color: #000000bf !important;
}


/* Animation */

@keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.animated {
    animation-duration: .3s;
    animation-fill-mode: both;
    -webkit-animation-duration: .3s;
    -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
    opacity: 0
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}

.preload {
    background-image: linear-gradient(-90deg, rgba(255, 255, 255, 0) 30%, #ffffffb3 45.52%, rgba(255, 255, 255, 0) 80%);
    background-repeat: no-repeat;
    animation: bg-move linear 2s infinite;
   }