body {
  font-family: 'SF Pro Display', sans-serif !important;
}

.backicon {
  transform: translateX(0);
  transition: transform .3s;
  cursor: pointer;
 }

 .backicon:hover {
  transform: translateX(-4px);
  color: #019EF7 !important;
 }

.slider {
  margin: auto;
  overflow: hidden;
  position: relative;
  width: auto;
}

/* .slider::before,
.slider::after {
  background: linear-gradient(to right, #E8E8E8 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 100px;
  position: absolute;
  width: 100px;
  z-index: 0;
}

.slider-content::before {
  content: "";
  height: 100px;
  position: absolute;
  width: 100px;
  z-index: 0;
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slider::before {
  left: 0;
  top: 0;
} */

.slider .slide-track {
  -webkit-animation: scroll 60s linear infinite;
  animation: scroll 60s linear infinite;
  display: flex;
  width: fit-content;
}

.slider .slide-track-reverse {
  -webkit-animation: scroll 20s linear infinite;
  animation: scroll-reverse 20s linear infinite;
  display: flex;
  width: fit-content;
}

.slider .slide-track-h {
  -webkit-animation: scroll-h 60s linear infinite;
  animation: scroll-h 60s linear infinite;
  display: flex;
  width: fit-content;
}

.slider .slide-track-reverse-h {
  -webkit-animation: scroll-h 20s linear infinite;
  animation: scroll-reverse-h 20s linear infinite;
  display: flex;
  width: fit-content;
}

.slider .slide {
  width: fit-content;
  margin: 0 50px;
  display: flex;
}

@-webkit-keyframes scroll-h {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes scroll-h {
  0% {
    transform: translateX(-50%);
  }
  100% {
    /* scroll only 50% */
    transform: translateX(0);
  }
}

@-webkit-keyframes scroll-reverse-h {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes scroll-reverse-h {
  0% {
    transform: translateX(0);
  }
  100% {
    /* scroll only 50% */
    transform: translateX(-50%);
  }
}

@-webkit-keyframes scroll {
  0% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes scroll {
  0% {
    transform: translateY(-50%);
  }
  100% {
    /* scroll only 50% */
    transform: translateY(0);
  }
}

@-webkit-keyframes scroll-reverse {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%);
  }
}

@keyframes scroll-reverse {
  0% {
    transform: translateY(0);
  }
  100% {
    /* scroll only 50% */
    transform: translateY(-50%);
  }
}
 
 .sa {
    width: 140px;
    height: 140px;
    padding: 26px;
    background-color: var(--kt-card-bg);
 }
  .sa-success {
    border-radius: 50%;
    border: 4px solid #50cd89;
    box-sizing: content-box;
    height: 80px;
    padding: 0;
    position: relative;
    background-color: var(--kt-card-bg);
    width: 80px;
 }
  .sa-success:after, .sa-success:before {
    background: var(--kt-card-bg);
    content: '';
    height: 120px;
    position: absolute;
    transform: rotate(45deg);
    width: 60px;
 }
  .sa-success:before {
    border-radius: 40px 0 0 40px;
    width: 26px;
    height: 80px;
    top: -17px;
    left: 5px;
    transform-origin: 60px 60px;
    transform: rotate(-45deg);
 }
  .sa-success:after {
    border-radius: 0 120px 120px 0;
    left: 30px;
    top: -11px;
    transform-origin: 0 60px;
    transform: rotate(-45deg);
    animation: rotatePlaceholder 4.25s ease-in;
    animation-delay: .25s;
 }
  .sa-success-placeholder {
    border-radius: 50%;
    border: 4px solid #50cd8849;
    box-sizing: content-box;
    height: 80px;
    left: -4px;
    position: absolute;
    top: -4px;
    width: 80px;
    z-index: 2;
 }
  .sa-success-fix {
    background-color: var(--kt-card-bg);
    height: 90px;
    left: 28px;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 5px;
    z-index: 1;
 }
  .sa-success-tip, .sa-success-long {
    background-color: #50cd89;
    border-radius: 2px;
    height: 5px;
    position: absolute;
    z-index: 2;
 }
  .sa-success-tip {
    left: 14px;
    top: 46px;
    transform: rotate(45deg);
    width: 25px;
    animation: animateSuccessTip .75s;
    animation-delay: .25s;
 }
  .sa-success-long {
    right: 8px;
    top: 38px;
    transform: rotate(-45deg);
    width: 47px;
    animation: animateSuccessLong .75s;
    animation-delay: .25s;
 }
  @keyframes animateSuccessTip {
    0%, 54% {
      width: 0;
      left: 1px;
      top: 19px;
   }
    70% {
      width: 50px;
      left: -8px;
      top: 37px;
   }
    84% {
      width: 17px;
      left: 21px;
      top: 48px;
   }
    100% {
      width: 25px;
      left: 14px;
      top: 45px;
   }
 }
  @keyframes animateSuccessLong {
    0%, 65% {
      width: 0;
      right: 46px;
      top: 54px;
   }
    84% {
      width: 55px;
      right: 0;
      top: 35px;
   }
    100% {
      width: 47px;
      right: 8px;
      top: 38px;
   }
 }
  @keyframes rotatePlaceholder {
    0%, 5% {
      transform: rotate(-45deg);
   }
    100%, 12% {
      transform: rotate(-405deg);
   }
 }

 .swal2-close:focus {
    box-shadow: none !important;
 }

  .swal2-popup .swal2-html-container {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .swal2-title {
    padding: 1.5em 1em 0 !important;
  }
  
  .swal2-icon {
    margin-top: 3em !important;
  }

  .swal2-popup {
    border-radius: 12px !important;
  }
  
  .swal2-popup .swal2-actions {
    margin: 0.25rem auto 1.75rem auto !important;
}

.rounded-modal {
  border-radius: 12px !important;
}

.rounded-6 {
  border-radius: 6px;
}

.w-checkbox {
 width: 22.75px;
}

.rotate-180 {
  rotate: 180deg !important;
}

.shine {
  background-image: linear-gradient(-90deg, rgba(255, 255, 255, 0) 30%, #ffffff5e 45.52%, rgba(255, 255, 255, 0) 80%);
  background-repeat: no-repeat;
  animation: bg-move linear 4s infinite;
 }

 @-webkit-keyframes bg-move {
  0%   { background-position: -400px 0; }
  100% { background-position: 600px 0; }
}
@keyframes bg-move {
  0%   { background-position: -400px 0; }
  100% { background-position: 700px 0; }
}

.shine-sm {
  background-image: linear-gradient(-90deg, rgba(255, 255, 255, 0) 30%, #ffffff5e 45.52%, rgba(255, 255, 255, 0) 80%);
  background-repeat: no-repeat;
  animation: bg-move-sm linear 3s infinite;
 }

 @-webkit-keyframes bg-move-sm {
  0%   { background-position: -100px 0; }
  100% { background-position: 150px 0; }
}
@keyframes bg-move-sm {
  0%   { background-position: -100px 0; }
  100% { background-position: 150px 0; }
}

.overlay-shine {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 10%, #00eeff6c 45%, rgba(255, 255, 255, 0) 80%);
  background-repeat: no-repeat;
  animation: bg-move-2 linear 5s infinite;
 }

 @-webkit-keyframes bg-move-2 {
  0%   { background-position: -400px 0; }
  100% { background-position: 1200px 0; }
}
@keyframes bg-move-2 {
  0%   { background-position: -400px 0; }
  100% { background-position: 1200px 0; }
}

.select-with-images {
  position: relative;
  width: 100%;
}

.select-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.select-option {
  display: flex;
  align-items: center;
}

.select-option img {
  margin-right: 10px;
  max-width: 20px;
  max-height: 20px;
}

.select-no-options {
  padding: 10px;
  text-align: center;
}

.select-no-options::after {
  content: " 😞";
}

.select-dropdown-with-image {
  position: absolute;
    width: inherit;
    background-color: var(--kt-card-bg);
    z-index: 1;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}

input[type=number] { 
    -moz-appearance: textfield; 
}

@keyframes changeHue {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

.change-hue-animation {
  animation: changeHue 1s linear infinite;
}

.loading-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.column_1 {
  width: 5px;
  height: 10px;
  border-radius: 3px;
  background-color: #009ef7;
  animation: ima 1000ms infinite;
}

.column_2 {
  width: 5px;
  height: 15px; /* Adjust the height value as per your preference */
  margin-left: 7px;
  background-color: #0082ce;
  border-radius: 3px;
  animation: ima 1000ms infinite;
  animation-delay: 100ms;
}

.column_3 {
  width: 5px;
  height: 20px; /* Adjust the height value as per your preference */
  margin-left: 7px;
  background-color: #00639c;
  border-radius: 3px;
  animation: ima 1000ms infinite;
  animation-delay: 200ms;
}

.column_4 {
  width: 5px;
  height: 25px; /* Adjust the height value as per your preference */
  margin-left: 7px;
  background-color: #004a75;
  border-radius: 3px;
  animation: ima 1000ms infinite;
  animation-delay: 300ms;
}

@keyframes ima {
  30% {
    height: 40px; /* Adjust the height value as per your preference */
  }
  60% {
    height: 10px; /* Adjust the height value as per your preference */
  }
}

.doughnut { 
  background-color: transparent;
    width: 195px;
    height: 195px;
    border: #f2f2f7 35px solid;
    border-radius: 50%;
}

.chart-series-labels {
  height: 12px;
  width: 12px;
  left: 0px;
  top: 0px;
  border-radius: 12px;
}

.border-red {
  border: solid 1px #f1416c;
}
.container-x {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.block {
  width: 70px;
  height: 70px;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(-35deg) rotateY(45deg) rotateZ(0deg);
  animation: rotate 2.5s cubic-bezier(0.75, 0, 0.25, 1) forwards;
}

.face {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid rgb(0, 56, 88);
  animation: backgroundToggle 5s;
  backdrop-filter: blur(3px);
}

@keyframes backgroundToggle {
  0% {
    background: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
  }
  100% {
    background: linear-gradient(rgba(0, 158, 247, 0.2), rgba(0, 158, 247, 0));
  }
}

.face.top {
  transform: translateY(-35px) rotateX(90deg);
}

.face.right {
  transform: translateX(35px) rotateY(90deg);
}

.face.left {
  transform: translateX(-35px) rotateY(-90deg);
}

.face.front {
  transform: translateZ(35px);
}

.face.back {
  transform: translateZ(-35px) rotateY(180deg);
}

.face.bottom {
  transform: translateY(35px) rotateX(-90deg);
}

@keyframes rotate {
  0% {
    transform: rotateX(55deg) rotateZ(45deg);
  }
  100% {
    transform: rotateX(55deg) rotateZ(405deg);
  }
}

.stepper-carousel .carousel-inner {
  overflow: visible;
}

.copy-hover {
  transition: background-color 0.3s, padding 0.3s, border-radius 0.3s;
}

.copy-hover:hover {
  border-radius: 3px;
  background-color: #79797924;
}

.webappAddress {
max-width: 74% !important;
 word-wrap: break-word !important
}

.fixed-footer {
    align-items: center;
    background: rgb(21,21,21);
    background: linear-gradient(45deg, rgb(0, 1, 44) 21%, #2d3a7b 100%);
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 6;
    left: 0;
    padding: 16px;
}

.banner-text{
  outline: 0;
    color: #c1bdbd;
    text-align: left;
    font-size: 13px;
    padding: 0;
    margin: 0;
}

.btn-link {
  background-color: transparent !important;
    border: none !important;
    color: var(--kt-primary);
}

.menu-state-title-primary .menu-item .menu-link.active {
  transition: color 0.2s ease;
  color: var(--kt-primary) !important;
}


.Toastify__toast{
  min-height: 48px !important;
}
@keyframes fadeDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Applying the animation when the div is shown */
.fade-down {
  animation: fadeDown 0.4s ease-in-out;
}

.disable {
  opacity: 0.5;
  pointer-events: none;
}

.pointer-event-none {
  pointer-events: none !important;
}

.accordion.accordion-icon-toggle .accordion-icon i, .accordion.accordion-icon-toggle .accordion-icon .svg-icon {
  color: var(--kt-dark);
}

.logo-loading {
  animation: logoloading 1.5s ease-in-out infinite;
}

@keyframes logoloading  {
  0% {
    transform: scale(1);
    opacity: 0.1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.3;
  }
  100% {
    transform: scale(1);
    opacity: 0.1;
  }
}

.splash-screen img {
  height: 30px !important;
}

.telegram-widget-support {
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 500;
}

.blend-mode-overlay {
  mix-blend-mode: overlay;
}

.bg-blur {
background: rgb(144 144 144 / 18%);
backdrop-filter: blur(6.8px);
-webkit-backdrop-filter: blur(6.8px);
border: 0.5px solid rgba(255, 255, 255, 0.144);
}

.bg-transparent-dark {
  background: rgba(97, 97, 97, 0.38);
  border: 0.5px solid rgba(171, 171, 171, 0.144);
  }

.nowrap {
 white-space: nowrap
}

 